






import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import SpotChecksFilterService, { SpotChecksFilterServiceS } from '../../spotchecks-filter.service';

@Component({
    components: { CustomMultiSelect },
})
export default class SpotChecksCompetitorsFilter extends Vue {
    @Inject(SpotChecksFilterServiceS) private spotChecksFilterService!: SpotChecksFilterService;

    get currentValue() {
        return (this.spotChecksFilterService.competitors || []).map(competitor => ({ name: competitor, value: competitor }));
    }

    set currentValue(value: { name: string, value: string }[]) {
        this.spotChecksFilterService.competitors = value.map(competitor => competitor.value);
    }

    get options() {
        const { competitorsList } = this.spotChecksFilterService;

        return competitorsList.map(value => ({
            name: value,
            value,
        }));
    }
}
