










import Screenshot from '@/modules/cars/components/screenshot-popup/screenshot.vue';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
        ModalWrapper,
        Screenshot,
    },
})

export default class SpotCheckScreenshotPopup extends Vue {
    @Prop({
        required: true,
        type: String,
    })
    public url!: string;

    close() {
        this.$emit('close');
    }
}
