




















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import CarHeader from '@/modules/cars/components/car-header.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import AvailabilityTooltip from '@/modules/cars/components/availability-tooltip.vue';
import SpotChecksActions from '../components/spotchecks-actions.vue';
import SpotChecksService, { SpotChecksServiceS } from '../spotchecks.service';
import SpotChecksTable from '../components/spotchecks-table.vue';

@Component({
    components: {
        PageWrapper,
        CarHeader,
        AvailabilityTooltip,
        SpotChecksActions,
        SpotChecksTable,
    },
})
export default class SpotChecksPage extends Vue {
    @Inject(UserServiceS) userService!: UserService;
    @Inject(SpotChecksServiceS) private spotChecksService!: SpotChecksService;
}
