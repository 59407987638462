



















import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import _ from 'lodash';
import moment from 'moment';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomDatePicker from '@/modules/common/components/ui-kit/custom-datepicker.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import LocationAvailabilityToggle from '../../location-availability/components/location-availability-toggle.vue';
import SpotChecksStore from '../store/spotchecks.store';
import SpotChecksService, { SpotChecksServiceS } from '../spotchecks.service';
import SpotChecksFilterService, { SpotChecksFilterServiceS } from '../spotchecks-filter.service';
import { ICustomSelectValue } from '../models/spotchecks-settings.model';
import SpotChecksCompetitorsFilter from './actions/spotchecks-competitors-filter.vue';
import SpotChecksPosFilter from './actions/spotchecks-pos-filter.vue';
import SpotChecksDataSourceFilter from './actions/spotchecks-datasource-filter.vue';
import SpotChecksCountryFilter from './actions/spotchecks-country-filter.vue';
import SpotChecksPickUpCityFilter from './actions/spotchecks-locations-filter.vue';

@Component({
    components: {
        CustomSelect,
        CustomDatePicker,
        LocationAvailabilityToggle,
        SpotChecksCompetitorsFilter,
        SpotChecksPosFilter,
        SpotChecksDataSourceFilter,
        SpotChecksCountryFilter,
        SpotChecksPickUpCityFilter,
    },
})
export default class SpotChecksActions extends Vue {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(CarsFiltersServiceS) carsFiltersService!: CarsFiltersService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(SpotChecksServiceS) protected spotChecksService!: SpotChecksService;
    @Inject(SpotChecksFilterServiceS) protected spotChecksFilterService!: SpotChecksFilterService;

    readonly storeState: SpotChecksStore = this.storeFacade.getState('SpotChecksStore');
    protected lok: string = '1';
    protected competitors: ICustomSelectValue | null = null;

    get getLokList() {
        return [{
            name: '1',
            value: '1',
        }];
    }

    set selectedDate(value: Date) {
        if (value) {
            this.storeState.settings.date = value.toDateString();
        }
    }

    get selectedDate(): Date {
        const { date } = this.storeState.settings;
        return date ? new Date(date) : new Date();
    }

    get customStyle() {
        return {
            minWidth: '160px',
        };
    }

    isDisabledDate(date: Date) {
        const utcDate = moment.utc(date).startOf('day');
        const todayDate = moment.utc();
        return utcDate.isSameOrAfter(todayDate.startOf('day')) || utcDate.isBefore(todayDate.subtract(10, 'days'));
    }
}
