














































import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Col, Row, Table, TableColumn } from 'element-ui';
import { ISpotChecksPhaseAvailability } from '../models/spotchecks-document.model';
import SpotCheckScreenshotPopup from './spotchecks-screenshot-popup.vue';

@Component({
    components: {
        'el-table': Table,
        'el-table-column': TableColumn,
        'el-row': Row,
        'el-col': Col,
        ModalWrapper,
        SpotCheckScreenshotPopup,
    },
})

export default class SpotCheckDetailsPopup extends Vue {
    public isPopupOpen: boolean = false;
    public url: string = '';

    @Prop({
        required: true,
        type: Array,
    })
    public tableData!: ISpotChecksPhaseAvailability[];

    close() {
        this.$emit('close');
    }

    closePopup() {
        this.isPopupOpen = false;
    }

    async openScreenshotPopup(url: string) {
        this.url = url;
        this.isPopupOpen = true;
    }
}

