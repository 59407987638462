









import { Vue, Component, Prop } from 'vue-property-decorator';
import { DatePicker } from 'element-ui';

@Component({
    components: {
        'el-date-picker': DatePicker,
    },
})
export default class CustomDatePicker extends Vue {
    @Prop({
        required: false,
        type: Date,
    })
    private value!: Date;

    @Prop({
        required: true,
        type: String,
    })
    private label!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private disabled?: boolean;

    @Prop({
        required: false,
        type: String,
    })
    private title?: string;

    @Prop({
        type: String,
        required: false,
    })
    private placeholder!: string;

    @Prop({
        type: Object,
        required: false,
    })
    private pickerOptions?: object;

    @Prop({
        type: String,
        required: false,
    })
    format?: string;

    set date(value: Date) {
        this.$emit('input', value);
    }

    get date(): Date {
        return this.value || new Date();
    }
}
