





















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AvailabilityTooltip extends Vue {
    @Prop({
        required: true,
        type: String,
    })
    public title!: string;

    @Prop({
        required: true,
        type: String,
    })
    public description!: string;

    @Prop({
        required: true,
        type: String,
    })
    public availableText!: string;

    @Prop({
        required: true,
        type: String,
    })
    public naText!: string;

    @Prop({
        required: true,
        type: String,
    })
    public noDataText!: string;
}
