










import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import Item from '@/modules/common/interfaces/item.interface';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import SpotChecksService, { SpotChecksServiceS } from '../../spotchecks.service';

@Component({
    components: { CustomMultiSelect },
})
export default class SpotChecksDataSourceFilter extends Vue {
    @Inject(SpotChecksServiceS) private spotChecksService!: SpotChecksService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    get options(): Item[] {
        const { spotchecksDataSources } = this.carsSharedService.filters;

        if (!spotchecksDataSources) {
            return [];
        }

        return spotchecksDataSources.map(value => ({ name: value, value: value.toLowerCase() }));
    }

    get currentValue() {
        return this.spotChecksService.storeState.settings.dataSources.map(value => ({
            name: value,
            value,
        }));
    }

    set currentValue(value: Record<string, any>[]) {
        if (value) {
            this.spotChecksService.storeState.settings.dataSources = value.map(item => item.value);
        }
    }
}
