export enum DateFormats {
    DefaultDate = 'YYYY-MM-DD',
    DefaultDateTime = 'YYYY-MM-DD HH:mm:ss',
    ISODateTime = 'YYYY-MM-DDTHH:mm:ss',
    DateTimeUnderScore = 'YYYY_MM_DD_HH_mm_ss',
    ShortDate = 'MM/DD/YYYY',
    UnderScoreDate = 'YYYY_MM_DD',
    DayMonthYear = 'DD-MM-YYYY',
    TimeWithUnderScore = 'HH_mm_ss',
    TimeWithColons = 'HH:mm:ss',
    HourMinuteWithColons = 'HH:mm',
    DayOfWeek = 'dddd',
}
