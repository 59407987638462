

















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import { COUNTRIES_ANY } from '@/modules/cars/constants/car-filter-types.constant';
import SpotChecksFilterService, { SpotChecksFilterServiceS } from '../../spotchecks-filter.service';

@Component({
    components: { CustomSelect, StyledSearchbar },
})
export default class SpotChecksCountryFilter extends Vue {
    @Inject(SpotChecksFilterServiceS) private spotChecksFilterService!: SpotChecksFilterService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    private query: string | null = null;

    get items(): Item[] {
        let { countryNames } = this.spotChecksFilterService;

        if (!countryNames) {
            return [];
        }

        if (this.query) {
            const searchPattern = new RegExp(this.query.toLowerCase());
            countryNames = countryNames.filter(location => searchPattern.test(location.toLowerCase()));
        }

        const result = countryNames.sort().map(value => ({
            name: String(value),
            disabled: false,
            value,
        }));
        result.unshift({ name: COUNTRIES_ANY, disabled: true, value: '' });
        if (!result.length) {
            result.push({
                name: 'No results',
                value: 'No results',
                disabled: true,
            });
        }

        return result;
    }

    handleChange(query: string) {
        this.query = query;
    }

    get currentValue() {
        return this.spotChecksFilterService.country;
    }

    set currentValue(value) {
        if (value) {
            this.carsSharedService.saveCountry(value, true);
            this.spotChecksFilterService.locationQuery = '';
        }
    }
}
